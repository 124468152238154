import React, {useState} from "react"
import Image from '../ResuableImageWrapper';
import parse from 'html-react-parser';
import QuoteMark from '../../../assets/quote_lrg.inline.svg';
import VisibilitySensor from 'react-visibility-sensor';
import "../../../sass/components/reuseable_components/_case_testimonial.scss";


const CaseStudyTestimonial = (data, {  isInView }) => {

  
  let classList = [
    'case_testimonial',
    // 'is_inview'
  ]; 

  const removeBottomMargin = data.removeBottomMargin;

  if(removeBottomMargin === true){
    classList.push('no-margin');
  }


  const [isInview, setIsInview] = useState(false);

  function inviewOnChange (isVisible) {
    setIsInview(isVisible)
  }
  
  return (
    <VisibilitySensor partialVisibility={true} offset={{bottom:120}} active={!isInview}  onChange={inviewOnChange}>
      {({isVisible}) =>
        <section className={`${classList.join(" ")} ${isInview ? 'is_inview' : ''}`}>
          <div className="wrapper-900 clearfix">
            <div className="orange_quote"><span>&ldquo;</span></div>
            <div className="label">{data.labelHeading}</div>
            <div className="testimonial">{parse(data.quotation)}</div>
            <div className="quote">
              <Image 
              image={data?.personPhoto?.localFile?.childImageSharp?.fluid} 
              alt={data?.personPhoto?.altText}
              />

              {data.quotationCompany &&
              data.quotationCompanyLink &&
              data.quotationPerson &&
              data.quotationPosition ?
                <div className="person">{`${data.quotationPerson} | ${data.quotationPosition} | `}<a className="" href={data.quotationCompanyLink} target="_blank" rel="noreferrer noopener">{data.quotationCompany}</a></div>
              : <div className="person">{data.personPositionCompany}</div>
              }
            </div>
            <div className="large_quote">
              <QuoteMark />
            </div>
            <div className="horiz-line"><div className="line"></div></div>
          </div>
        </section>
      }
    </VisibilitySensor>
  )
}

export default CaseStudyTestimonial

